import './index.scss';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Wrapper from '../../../components/layout/wrapper';
import Table from '../../../components/table';
import NoData from '../../../components/no-data';
import { IoMdClose, IoMdRefreshCircle } from 'react-icons/io';
import Loading from '../../../components/loading';
import api from '../../../utils/api';
import sound from '../../../assets/sounds/notification.wav';
import Tablets from './tablets';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import DropDown from '../../../components/drop-down';
import { BiReset } from 'react-icons/bi';
import Warning from '../../../components/warning';
import { AiTwotoneEdit } from 'react-icons/ai';
import EditBranch from './edit-branch';

function Branches() {
  const { t } = useTranslation();
  const [mode, setMode] = useState('branches');
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState(null);
  const [tablets, setTablets] = useState(null);
  const [branchName, setBranchName] = useState('');
  const [branchAddress, setBranchAddress] = useState('');
  const [wifiPassword, setWifiPassword] = useState('');
  const [wifiUsername, setWifiUsername] = useState('');
  const [branchPhoneNumber, setBranchPhoneNumber] = useState('');
  const [serviceFee, setServiceFee] = useState('');
  const [addNewBranch, setAddNewBranch] = useState(false);
  const [addNewTablet, setAddNewTablet] = useState(false);
  const [error, setError] = useState(null);
  const [integrations, setIntegrations] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [warning, setWarning] = useState(false);
  const [editBranch, setEditBranch] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const BranchesTitles = useMemo(
    () => [
      {
        name: t('components.tables.name'),
        size: 200,
      },
      {
        name: t('dashboard.restaurant.address'),
        size: 200,
      },
      {
        name: t('general.phoneNumber'),
        size: 200,
      },
      {
        name: t('dashboard.restaurant.wifi_username'),
        size: 140,
      },
      {
        name: t('dashboard.restaurant.wifi_password'),
        size: 140,
      },
      {
        name: t('dashboard.restaurant.fee'),
        size: 140,
      },
      {
        name: t('components.tables.tablet'),
        size: 300,
      },
    ],
    [t]
  );

  const onRefresh = useCallback(() => {
    api
      .get(`/branches`)
      .then(({ data }) => {
        setBranches(data);
        setLoading(false);
        setSelectedBranch(
          data.map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj.id,
          }))[0]
        );
        const branch = data.map((obj) => ({
          ...obj,
          label: obj.name,
          value: obj.id,
        }))[0];
        api
          .get(`/tablets?branchId=${branch.id}`)
          .then(({ data }) => {
            setTablets(
              data.map((tablet) => {
                return {
                  ...tablet,
                  label: tablet.name,
                  value: tablet.id,
                };
              })
            );
            setLoading(false);
          })
          .catch((err) => {
            console.log(t(`errors.${errToString(err)}`));
            const audio = new Audio(sound);
            const info = `${errToString(err)}`;
            toast.info(info, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              type: 'error',
            });
            audio.play();
            console.log(errToString(err));
          });
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [t]);
  const onChangeField = useCallback((e, setter) => {
    setError(null);
    setter(e.target.value);
  }, []);
  const onChangeMode = useCallback(() => {
    setError(null);
    if (mode === 'branches') {
      setMode('tablets');
    }
    if (mode === 'tablets') {
      setMode('branches');
    }
  }, [mode]);
  const onAddNew = useCallback(() => {
    setError(null);

    if (mode === 'branches') {
      setAddNewBranch(true);
      setAddNewTablet(false);
    }
    if (mode === 'tablets') {
      setAddNewBranch(false);
      setAddNewTablet(true);
    }
  }, [mode]);
  const onCreateBranch = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      setBtnLoading(true);

      const data = {
        name: branchName,
        address: branchAddress,
        phoneNumber: branchPhoneNumber,
        wifiPassword,
        wifiUsername,
        serviceFee,
      };

      api
        .post('/branches', data)
        .then(({ data }) => {
          setAddNewBranch(false);
          setBranchName('');
          setBtnLoading(false);
          const audio = new Audio(sound);
          const info = t('notifications.branchCreated');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();

          return setBranches([...branches, data]);
        })
        .catch((err) => {
            setError(t(`errors.${errToString(err)}`));
            setBtnLoading(false);
            console.log(err);
        });
    },
    [
      t,
      branches,
      branchName,
      branchAddress,
      branchPhoneNumber,
      wifiPassword,
      wifiUsername,
      serviceFee,
    ]
  );
  const onUnfreezeBranch = useCallback(
    (branch) => {
      api
        .put(`/branches/${branch.id}`, {
          restore: true,
        })
        .then((res) => {
          const audio = new Audio(sound);
          const info = `${branch.name} ${t('notifications.branchActivated')}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          onRefresh();
          return setBranches(
            branches.map((_branch) => {
              if (branch.id === _branch.id) {
                return {
                  ...res.data,
                  deletedAt: null,
                  label: res.data.name,
                  value: res.data.id,
                };
              }
              return _branch;
            })
          );
        })
        .catch((err) => {
          console.log(t(`errors.${errToString(err)}`));
          alert(t(`errors.${errToString(err)}`));
        });
    },
    [t, branches, setBranches, onRefresh]
  );
  const onBranchDelete = useCallback(() => {
    api
      .delete(`/branches/${selectedBranch.id}`)
      .then(() => {
        setWarning(false);
        const audio = new Audio(sound);
        const info = `${selectedBranch.name} ${t('notifications.branchDeleted')}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
        onRefresh();
        return setBranches(
          branches.map((branch) => {
            if (selectedBranch.id === branch.id) {
              return {
                ...branch,
                label: branch.name,
                value: branch.id,
                deletedAt: new Date(),
              };
            }
            return branch;
          })
        );
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        alert(t(`errors.${errToString(err)}`));
      });
  }, [t, branches, selectedBranch, onRefresh]);

  useEffect(() => {
    api
      .get(`/branches`)
      .then((res) => {
        setBranches(
          res.data.map((obj) => ({
            ...obj,
            value: obj.id,
            label: obj.name,
          }))
        );
        setLoading(false);
        setSelectedBranch(
          res.data.map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj.id,
          }))[0]
        );
      })
      .catch((err) => console.log(t(`errors.${errToString(err)}`)));
  }, [t]);
  useEffect(() => {
    if (!selectedBranch) {
      return setTablets([]);
    }

    api
      .get(`/tablets?branchId=${selectedBranch.id}`)
      .then((res) => {
        setTablets(
          res.data.map((tablet) => {
            return {
              ...tablet,
              label: tablet.name,
              value: tablet.id,
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => console.log(t(`errors.${errToString(err)}`)));
  }, [t, selectedBranch]);
  useEffect(() => {
    api
      .get('/restaurant/integrations')
      .then((res) => {
        setIntegrations(res.data);
      })
      .catch((err) => {
        errToString(err);
      });
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            {!addNewBranch && !addNewTablet && !editBranch && (
              <>
                <h2>
                  {windowWidth > 600 && (
                    <>
                      {mode === 'branches'
                        ? t('routes.branches')
                        : t('routes.tablets')}
                    </>
                  )}
                  {mode === 'branches' && (
                    <IoMdRefreshCircle onClick={onRefresh} />
                  )}
                </h2>
                {mode === 'tablets' && (
                  <div className='page_in_header_filter'>
                    {!!branches?.length && (
                      <DropDown
                        placeholder={t('routes.branches')}
                        disabled={branches?.length < 2 ? true : false}
                        options={branches.map((branch, index) => ({
                          option: branch,
                          el: <li key={index}>{branch.label}</li>,
                        }))}
                        value={selectedBranch}
                        onChange={(e) => setSelectedBranch(e)}
                        position='bottom'
                        loading={false}
                        showClose={false}
                      />
                    )}
                  </div>
                )}
                {integrations && !integrations.length && (
                  <button
                    className='page_in_header_btn page_in_header_btn--add'
                    onClick={onAddNew}
                  >
                    {mode === 'branches'
                      ? t('general.addBranch')
                      : t('general.addTablet')}
                  </button>
                )}
                <button
                  className='page_in_header_btn page_in_header_btn--mode'
                  onClick={onChangeMode}
                >
                  {mode === 'branches'
                    ? t('routes.tablets')
                    : t('routes.branches')}
                </button>
              </>
            )}
            {!addNewTablet && !editBranch && addNewBranch && (
              <>
                <h2>{t('general.addBranch')}</h2>
                <button
                  className='page_in_header_back'
                  onClick={() => {
                    setAddNewBranch(false);
                    setBranchName('');
                  }}
                >
                  {t('general.back')}
                </button>
              </>
            )}
            {!addNewBranch && !editBranch && addNewTablet && (
              <>
                <h2>{t('general.addTablet')}</h2>
                <button
                  className='page_in_header_back'
                  onClick={() => setAddNewTablet(false)}
                >
                  {t('general.back')}
                </button>
              </>
            )}
            {!addNewBranch && !addNewTablet &&  editBranch && (
              <>
                      <h2>{t('general.editBranch')}</h2>
                      <button
                          className='page_in_header_back'
                          onClick={() => setEditBranch(false)}
                      >
                          {t('general.back')}
                      </button>
                  </>
            )}
          </div>
          {!loading && (
            <div className='page_in_content'>
              <>
                {!addNewBranch &&
                 !editBranch &&
                  !addNewTablet &&
                  mode === 'branches' &&
                  !!branches?.length && (
                    <Table
                      route={false}
                      titles={BranchesTitles}
                      hiddenFields={['id']}
                      loading={false}
                      rows={
                        branches.map((branch) => ({
                          id: branch.id,
                          name: <p>{branch?.name}</p>,
                          address: <p>{branch?.address}</p>,
                          phoneNumber: <p>{branch?.branchInfo?.phoneNumber}</p>,
                          wifiUsername: (
                            <p>{branch?.branchInfo?.wifiUsername}</p>
                          ),
                          wifiPassword: (
                            <p>{branch?.branchInfo?.wifiPassword}</p>
                          ),
                          serviceFee: <p>{branch?.branchInfo?.serviceFee}</p>,
                          tablet: !!branch?.tablets?.length ? (
                            <p>
                              {branch.tablets
                                .map(({ name }) => name)
                                .join(', ')}
                            </p>
                          ) : (
                            <p>{t('noData.tablets')}</p>
                          ),
                          actions: (
                            <div className='page_in_content_actions'>
                              <button
                                className='page_in_content_actions_btn page_in_content_actions_btn--edit'
                                onClick={() => {
                                  setSelectedBranch(branch);
                                  setEditBranch(true);
                                }}
                              >
                                <AiTwotoneEdit />
                              </button>
                              {!branch.deletedAt && (
                                <button
                                  className='page_in_content_actions_btn page_in_content_actions_btn--delete'
                                  onClick={() => {
                                    setSelectedBranch(branch);
                                    setAddNewBranch(false);
                                    setWarning(true);
                                  }}
                                >
                                  <IoMdClose />
                                </button>
                              )}
                              {branch.deletedAt && (
                                <button
                                  className='page_in_content_actions_btn page_in_content_actions_btn--reset'
                                  onClick={() => onUnfreezeBranch(branch)}
                                >
                                  <BiReset />
                                </button>
                              )}
                            </div>
                          ),
                        })) || []
                      }
                    />
                  )}
                {editBranch && !addNewBranch && (
                  <EditBranch
                    selectedBranch={selectedBranch}
                    setBranches={setEditBranch}
                    branches={branches}
                    setEditBranch={setEditBranch}
                  />
                )}
                {!addNewBranch &&
                  !editBranch &&
                  !addNewTablet &&
                  mode === 'branches' &&
                  !branches?.length && <NoData title={t('noData.branches')} />}
                {!addNewTablet && addNewBranch && (
                    <form className='form' onSubmit={onCreateBranch}>
                        <div className='form_columns'>
                            <div className='form_column'>
                                <label htmlFor=''>{t('components.tables.name')}</label>
                                <input
                                    type='text'
                                    value={branchName}
                                    maxLength={50}
                                    placeholder={t('components.tables.name')}
                                    onChange={(e) => onChangeField(e, setBranchName)}
                                />
                            </div>
                            <div className='form_column'>
                                <label htmlFor=''>
                                    {t('dashboard.restaurant.address')}
                                </label>
                                <input
                                    type='text'
                                    value={branchAddress}
                                    maxLength={50}
                                    placeholder={t('dashboard.restaurant.address')}
                                    onChange={(e) => onChangeField(e, setBranchAddress)}
                                />
                            </div>
                            <div className='form_column'>
                                <label htmlFor=''>
                                    {t('dashboard.restaurant.phone')}
                                </label>
                                <input
                                    type='text'
                                    value={branchPhoneNumber}
                                    placeholder={t('dashboard.restaurant.phone')}
                                    onChange={(e) =>
                                        onChangeField(e, setBranchPhoneNumber)
                                    }
                                />
                            </div>
                        </div>
                        <div className='form_columns'>
                            <div className='form_column'>
                                <label htmlFor=''>
                                    {t('dashboard.restaurant.wifi_username')}
                                </label>
                                <input
                                    type='text'
                                    value={wifiUsername}
                                    maxLength={50}
                                    placeholder={t('dashboard.restaurant.wifi_username')}
                                    onChange={(e) => onChangeField(e, setWifiUsername)}
                                />
                            </div>
                            <div className='form_column'>
                                <label htmlFor=''>
                                    {t('dashboard.restaurant.wifi_password')}
                                </label>
                                <input
                                    type='text'
                                    value={wifiPassword}
                                    maxLength={50}
                                    placeholder={t('dashboard.restaurant.wifi_password')}
                                    onChange={(e) => onChangeField(e, setWifiPassword)}
                                />
                            </div>
                        </div>
                        <div className='form_columns'>
                            <div className='form_column'>
                                <label htmlFor=''>
                                    {t('dashboard.restaurant.fee')}
                                </label>
                                <input
                                    type='text'
                                    value={serviceFee}
                                    maxLength={50}
                                    placeholder={t('dashboard.restaurant.fee')}
                                    onChange={(e) => onChangeField(e, setServiceFee)}
                                />
                            </div>
                            <div className='form_column'/>
                        </div>
                        <div className='form_error form_error--left'>
                            {error && (
                                <div className='form_error_name'>
                                    <PiWarningOctagonDuotone/>
                                    <p>{error}</p>
                                </div>
                            )}
                        </div>
                        <div className='form_actions'>
                            <button className='form_actions_btn' type={'submit'}>
                                {!btnLoading && t('general.save')}
                                {btnLoading && <Loading/>}
                            </button>
                        </div>
                    </form>
                )}
                  <Tablets
                      mode={mode}
                      addNewTablet={addNewTablet}
                      addNewBranch={addNewBranch}
                      setAddNewTablet={setAddNewTablet}
                      setTablets={setTablets}
                      tablets={tablets}
                      loading={loading}
                      branches={branches.map((branch) => ({
                    ...branch,
                    value: branch.id,
                    label: branch.name,
                  }))}
                />
              </>
              {warning && (
                <Warning
                  description={`${t('components.warning.mainTxt')} ${t('components.warning.block')} ${selectedBranch.name}`}
                  onCancel={() => setWarning(false)}
                  onSubmit={onBranchDelete}
                />
              )}
            </div>
          )}
          {loading && (
            <div className='loading_fullscreen'>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Branches);
