import './index.scss';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Wrapper from '../../../components/layout/wrapper';
import { useTranslation } from 'react-i18next';
import api from '../../../utils/api';
import { errToString } from '../../../utils';
import Loading from '../../../components/loading';
import AddLanguage from './add-language';
import EditLanguage from './edit-language';
import Warning from '../../../components/warning';
import LanguageDropDown from '../../../components/language-drop-down';
import { BsThreeDots } from 'react-icons/bs';
import Table from '../../../components/table';
import Category from './category';
import AddCategory from './category/add-category';
import MenuImport from './menu-import';
import NoData from '../../../components/no-data';
import Overlay from '../../../components/layout/overlay';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import DropDown from '../../../components/drop-down';
import { IoMdClose } from 'react-icons/io';
import { AiTwotoneEdit } from 'react-icons/ai';

function Menu() {
  const { t } = useTranslation();
  const morePopupRef = useRef();
  const [integrations, setIntegrations] = useState(null);
  const [hasActiveIntegration, setHasActiveIntegration] = useState(null);
  const [createdLanguages, setCreatedLanguages] = useState(null);
  const [importing, setImporting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showCreateLng, setShowCreateLng] = useState(false);
  const [selectedLng, setSelectedLng] = useState(null);
  const [selectedLngId, setSelectedLngId] = useState(null);
  const [editableLanguage, setEditableLanguage] = useState(null);
  const [deletableLanguage, setDeletableLanguage] = useState(null);
  const [showLanguages, setShowLanguages] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [categories, setCategories] = useState(null);
  const [addCategoryFormIsOpen, setAddCategoryFormIsOpen] = useState(false);
  const [importMenuFormIsOpen, setImportMenuFormIsOpen] = useState(false);
  const [labelName, setLabelName] = useState('');
  const [reordering, setReordering] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMore, setShowMore] = useState(false);
  const [deleteMenuWarning, setDeleteMenuWarning] = useState(false);
  const [labels, setLabels] = useState(null);
  const [branches, setBranches] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [activeIntegrationName, setActiveIntegrationName] = useState(null);
  const [loading, setLoading] = useState(true);

  const existingLanguagesNames = useMemo(() => {
    if (!createdLanguages) {
      return;
    }

    return createdLanguages.map(({ name }) => {
      return name.toUpperCase();
    });
  }, [createdLanguages]);
  const languageTitles = useMemo(
    () => [
      {
        name: t('components.tables.languages'),
      },
      {},
    ],
    [t]
  );

  const onLanguageDelete = useCallback(() => {
    api
      .delete(`restaurant/languages/${deletableLanguage.id}`)
      .then((res) => {
        setDeletableLanguage(null);
        setCreatedLanguages(res.data);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = errToString(err);
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        setDeletableLanguage(null);
      });
  }, [t, deletableLanguage]);
  const onRefreshCategories = useCallback(() => {
    if (!selectedBranch) {
      return;
    }
    api
      .get(
        `/product-categories?languageId=${selectedLngId}&freezed&branchId=${selectedBranch.id}`
      )
      .then(({ data }) => {
        return setCategories(data);
      })
      .catch((err) => {
        const audio = new Audio(sound);
        const info = errToString(err);
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(t(`errors.${errToString(err)}`));
      });
  }, [t, selectedLngId, selectedBranch]);
  const onMenuImport = useCallback(() => {
    setImporting(true);

    if (!activeIntegrationName) {
      return;
    }

    const data = {
      integrationCode: activeIntegrationName,
    };

    api
      .delete('/restaurant/import')
      .then(() => {
        api
          .post('/restaurant/import', data)
          .then(() => {
            const audio = new Audio(sound);
            const info = t('notifications.importMenu');
            toast.info(info, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
            audio.play();
            window.location.reload();
          })
          .catch((err) => {
            console.log(errToString(err));
            setImporting(false);
            const audio = new Audio(sound);
            const info = t('errors.somethingWentWrong');
            toast.info(info, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              type: 'error',
            });
            audio.play();
          });
      })
      .catch((err) => {
        console.log(errToString(err));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
        setImporting(false);
        setLoading(false);
      });
  }, [t, activeIntegrationName]);
  const onMenuDelete = useCallback(() => {
    setDeleting(true);
    setDeleteMenuWarning(false);

    api
      .delete('/restaurant/import')
      .then(() => {
        const audio = new Audio(sound);
        const info = t('notifications.deleteMenu');
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
        setDeleting(false);
        onRefreshCategories();
      })
      .catch((err) => {
        const audio = new Audio(sound);
        const info = errToString(err);
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
        setDeleting(false);
      });
  }, [onRefreshCategories, t]);
  const onSaveLabel = useCallback(() => {
    const data = {
      name: labelName,
    };

    api
      .post(`/products/labels`, data)
      .then(({ data }) => {
        setLabels([...labels, data]);
        setLabelName('');
      })
      .catch((err) => {
        console.log(errToString(err));
        const audio = new Audio(sound);
        const info = errToString(`general.${err}`);
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
      });
  }, [labelName, labels]);

  const onSubmitCategoriesReorder = useCallback(() => {
    let resultObject = {};
    const updatedObjects = [...categories.map(({ id }) => id)];
    updatedObjects.forEach(
      (number, index) => (resultObject[number] = index + 1)
    );
    setReordering(true);

    api
      .put(`/product-categories/sort`, resultObject)
      .then(() => {
        setReordering(false);
      })
      .catch((err) => {
        console.error('Error updating positions:', errToString(err));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [categories]);

  useEffect(() => {
    api
      .get('/restaurant/integrations')
      .then((res) => {
        setIntegrations(res.data);
        if (!!res.data.length) {
          setHasActiveIntegration(
            res.data.find(({ isActive }) => isActive) || null
          );
          setActiveIntegrationName(
            res.data.find(({ isActive }) => isActive).name || null
          );
        } else {
          setHasActiveIntegration(null);
          setActiveIntegrationName(null);
        }
      })
      .catch((err) => {
        console.log(errToString(err));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, []);
  useEffect(() => {
    api
      .get('restaurant/languages')
      .then((res) => {
        setCreatedLanguages(res.data);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [t]);
  useEffect(() => {
    if (!createdLanguages) {
      return;
    }

    if (createdLanguages && !createdLanguages?.length) {
      return setShowCreateLng(true);
    }

    if (createdLanguages && !!createdLanguages?.length) {
      if (selectedLng) {
        setSelectedLngId(
          createdLanguages.find(
            ({ name }) => name.toUpperCase() === selectedLng
          ).id
        );
      }
      if (!selectedLng) {
        setSelectedLngId(
          createdLanguages.find(({ isPrimary }) => isPrimary).id
        );
        setSelectedLng(
          createdLanguages.find(({ isPrimary }) => isPrimary).name.toUpperCase()
        );
      }
      if (deletableLanguage) {
        if (deletableLanguage.id === selectedLngId) {
          setSelectedLngId(
            createdLanguages.find(({ isPrimary }) => isPrimary).id
          );
          setSelectedLng(
            createdLanguages
              .find(({ isPrimary }) => isPrimary)
              .name.toUpperCase()
          );
        }
      }
    }
  }, [createdLanguages, selectedLng, deletableLanguage, selectedLngId]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    api
      .get('/products/labels')
      .then(({ data }) => {
        setLabels(data);
      })
      .catch((err) => {
        console.log(err);
        const audio = new Audio(sound);
        const info = `${err}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, []);
  useEffect(() => {
    setLoading(true);

    api
      .get('/branches')
      .then(({ data }) => {
        if (!!data.length) {
          setBranches(
            data.map((obj) => ({
              ...obj,
              label: obj.name,
              value: obj.id,
            }))
          );
          setSelectedBranch(
            data.map((obj) => ({
              ...obj,
              label: obj.name,
              value: obj.id,
            }))[0]
          );

          if (!selectedLngId) {
            return;
          }
        } else {
          setBranches([]);
        }
      })
      .catch((err) => {
        console.log(errToString(err));
      });
  }, [selectedLngId, t]);
  useEffect(() => {
    if (!selectedBranch) {
      return;
    }

    setLoading(true);

    api
      .get(
        `/product-categories?languageId=${selectedLngId}&freezed&branchId=${selectedBranch.id}`
      )
      .then(({ data }) => {
        setCategories(
          data.map((obj, index) => ({
            ...obj,
            label: obj.name,
            value: obj.id,
            position: index + 1,
          }))
        );
        return setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(t(`errors.${errToString(err)}`));
      });
  }, [selectedBranch, selectedLngId, t]);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            <div className='menu_in_header_lng'>
              {!showLanguages && !showLabels && <h2>{t('routes.menu')}</h2>}
              {showLabels && <h2>{t('general.labels')}</h2>}
              {showLanguages && <h2>{t('general.languages')}</h2>}
              {createdLanguages && !!createdLanguages?.length && (
                <>
                  {!showLanguages && !showLabels && (
                    <>
                      <LanguageDropDown
                        selected={selectedLng}
                        setSelected={setSelectedLng}
                        searchable={false}
                        className={'menu_in_header_lng_drop_down'}
                        countries={existingLanguagesNames}
                      />
                      {createdLanguages
                        .filter(({ isPrimary }) => isPrimary)[0]
                        .name?.toLowerCase() === selectedLng?.toLowerCase() && (
                        <div className='menu_in_header_lng_label'>
                          {t('general.primary')}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className='menu_in_header_actions'>
              {createdLanguages && !!createdLanguages?.length && (
                <>
                  {!showLanguages && !showLabels && (
                    <>
                      <div className='page_in_header_filter'>
                        {!!branches?.length && (
                          <DropDown
                            placeholder={t('routes.branches')}
                            disabled={branches?.length < 2 ? true : false}
                            options={branches.map((branch, index) => ({
                              option: branch,
                              el: <li key={index}>{branch.label}</li>,
                            }))}
                            value={selectedBranch}
                            onChange={(e) => setSelectedBranch(e)}
                            position='bottom'
                            loading={false}
                            showClose={false}
                          />
                        )}
                      </div>
                      <div
                        ref={morePopupRef}
                        className='page_in_header_icon'
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowMore(true);
                        }}
                      >
                        <BsThreeDots />
                        {showMore &&
                          morePopupRef.current &&
                          createPortal(
                            <>
                              <div
                                className={cn('page_in_content_actions_popup', {
                                  'page_in_content_actions_popup--show':
                                    showMore,
                                })}
                                style={{
                                  top:
                                    morePopupRef.current.getBoundingClientRect()
                                      .top + 40,
                                  right: 20,
                                  width: windowWidth > 400 ? 300 : 250,
                                }}
                              >
                                <>
                                  {!showLanguages && !showLabels && (
                                    <>
                                      <button
                                        className='page_in_content_actions_more_btn'
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowMore(false);
                                          setShowLanguages(!showLanguages);
                                        }}
                                      >
                                        {t('general.languages')}
                                      </button>
                                      <button
                                        className='page_in_content_actions_more_btn'
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowLabels(!showLabels);
                                          setShowMore(false);
                                        }}
                                      >
                                        {t('general.labels')}
                                      </button>
                                    </>
                                  )}
                                </>
                                {!showLanguages && !showLabels && (
                                  <>
                                    <button
                                      className='page_in_content_actions_more_btn'
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setReordering(true);
                                        setShowMore(false);
                                      }}
                                    >
                                      {t('general.reorderCategories')}
                                    </button>
                                    {hasActiveIntegration && (
                                      <button
                                        className='page_in_content_actions_more_btn'
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          onMenuImport();
                                          setShowMore(false);
                                        }}
                                      >
                                        {t('general.syncMenu')}
                                      </button>
                                    )}
                                    {!hasActiveIntegration && (
                                      <>
                                        <button
                                          className='page_in_content_actions_more_btn'
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setAddCategoryFormIsOpen(true);
                                            setShowMore(false);
                                          }}
                                        >
                                          {t('general.addCategory')}
                                        </button>
                                        <button
                                          className='page_in_content_actions_more_btn'
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setImportMenuFormIsOpen(true);
                                            setShowMore(false);
                                          }}
                                        >
                                          {t('general.import')}
                                        </button>
                                      </>
                                    )}
                                    <button
                                      className='page_in_content_actions_more_btn'
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteMenuWarning(true);
                                        setShowMore(false);
                                      }}
                                    >
                                      {t('general.deleteMenu')}
                                    </button>
                                  </>
                                )}
                              </div>
                              <Overlay
                                isTransparent={true}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowMore(false);
                                }}
                              />
                            </>,
                            document.getElementById('modal')
                          )}
                      </div>
                    </>
                  )}
                  {!showLabels && showLanguages && (
                    <>
                      <button
                        style={{ marginRight: 10 }}
                        className='page_in_header_btn page_in_header_btn--add'
                        onClick={() => setShowCreateLng(true)}
                      >
                        {t('general.addLanguage')}
                      </button>
                      <button
                        className='page_in_header_back'
                        onClick={() => setShowLanguages(!showLanguages)}
                      >
                        {t('general.back')}
                      </button>
                    </>
                  )}
                  {showLabels && !showLanguages && (
                    <>
                      <button
                        className='page_in_header_back'
                        onClick={() => {
                          setShowLabels(!showLabels);
                        }}
                      >
                        {t('general.back')}
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {integrations && !loading && (
            <div className='page_in_content'>
              {!importing && !deleting && !loading && (
                <>
                  {createdLanguages && !!createdLanguages?.length && (
                    <>
                      {showLanguages && (
                        <Table
                          route={false}
                          titles={languageTitles}
                          rows={
                            createdLanguages?.map((lng) => ({
                              language: (
                                <div className='menu_in_content_lng'>
                                  <img
                                    src={`https://hatscripts.github.io/circle-flags/flags/${lng.name?.toLowerCase()}.svg`}
                                    width='48'
                                    alt={lng.name}
                                  />
                                  <p>{lng.name.toUpperCase()}</p>
                                  {lng.isPrimary && (
                                    <button className='menu_in_header_lng_label'>
                                      {t('general.primary')}
                                    </button>
                                  )}
                                </div>
                              ),
                              actions: (
                                <div className='page_in_content_actions'>
                                  <button
                                    className='page_in_content_actions_btn page_in_content_actions_btn--edit'
                                    onClick={() => setEditableLanguage(lng)}
                                  >
                                    <AiTwotoneEdit />
                                  </button>
                                  <button
                                    className='page_in_content_actions_btn page_in_content_actions_btn--delete'
                                    onClick={() => setDeletableLanguage(lng)}
                                  >
                                    <IoMdClose />
                                  </button>
                                </div>
                              ),
                            })) || []
                          }
                          loading={false}
                        />
                      )}
                      {showLabels && !labels && <Loading />}
                      {showLabels && labels && (
                        <>
                          <div className='menu_in_content_labels'>
                            {!!labels.length &&
                              labels.map((label) => (
                                <div
                                  key={label.id}
                                  className='menu_in_content_labels_label'
                                >
                                  <p className='menu_in_content_labels_label_name'>
                                    {label.name}
                                  </p>
                                </div>
                              ))}
                            {!labels.length && (
                              <NoData title={t('noData.labels')} />
                            )}
                          </div>
                          <div className='menu_labels'>
                            <h3 className='menu_labels_title'>
                              {t('general.createLabel')}
                            </h3>
                            <div className='menu_labels_in'>
                              <input
                                className='menu_labels_in_name'
                                type='text'
                                placeholder={t('components.tables.name')}
                                value={labelName}
                                onChange={(e) => setLabelName(e.target.value)}
                              />
                              <button
                                className='menu_labels_btn'
                                onClick={onSaveLabel}
                              >
                                {t('general.createLabel')}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {!showLanguages && !showLabels && (
                        <>
                          {!!categories?.length &&
                            categories.map((category, index) => (
                              <Category
                                key={category.id}
                                index={index}
                                category={category}
                                categories={categories}
                                setCategories={setCategories}
                                selectedLngId={selectedLngId}
                                primaryLngId={
                                  createdLanguages.find(
                                    ({ isPrimary }) => isPrimary
                                  ).id
                                }
                                selectedLng={selectedLng}
                                hasDefaultIntegration={hasActiveIntegration}
                                type={'default'}
                                labels={labels}
                              />
                            ))}
                          {!!categories?.length &&
                            reordering &&
                            createPortal(
                              <div
                                className='menu_modal menu_modal--active'
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className='menu_modal_title'>
                                  {t('general.reorderCategories')}
                                </div>
                                <div className='menu_modal_in'>
                                  {categories.map((category, index) => (
                                    <Category
                                      key={category.id}
                                      index={index}
                                      category={category}
                                      categories={categories}
                                      setCategories={setCategories}
                                      selectedLngId={selectedLngId}
                                      primaryLngId={
                                        createdLanguages.find(
                                          ({ isPrimary }) => isPrimary
                                        ).id
                                      }
                                      selectedLng={selectedLng}
                                      hasDefaultIntegration={
                                        hasActiveIntegration
                                      }
                                      type={'grab'}
                                      labels={labels}
                                    />
                                  ))}
                                </div>
                                <div className='menu_modal_actions'>
                                  <button
                                    className='menu_modal_actions_btn menu_modal_actions_btn--cancel'
                                    onClick={() => {
                                      setReordering(false);
                                      setCategories(categories);
                                      onRefreshCategories();
                                    }}
                                  >
                                    {t('general.cancel')}
                                  </button>
                                  <button
                                    className='menu_modal_actions_btn menu_modal_actions_btn--submit'
                                    onClick={onSubmitCategoriesReorder}
                                  >
                                    {t('general.save')}
                                  </button>
                                </div>
                                <Overlay
                                  onClick={() => {
                                    setReordering(false);
                                    onRefreshCategories();
                                  }}
                                />
                              </div>,
                              document.getElementById('modal')
                            )}
                          {!branches?.length && (
                            <NoData title={t('noData.branches')} />
                          )}
                          {!!branches?.length && !categories?.length && (
                            <NoData title={t('noData.categories')} />
                          )}
                        </>
                      )}
                    </>
                  )}
                  {!!createdLanguages?.length &&
                    !categories &&
                    selectedBranch && <Loading />}
                  {showCreateLng && (
                    <AddLanguage
                      primary={!createdLanguages?.length}
                      setShowCreateLng={setShowCreateLng}
                      setCreatedLanguages={setCreatedLanguages}
                    />
                  )}
                  {importMenuFormIsOpen && (
                    <MenuImport
                      branch={selectedBranch}
                      setImportMenuFormIsOpen={setImportMenuFormIsOpen}
                      setImporting={setImporting}
                      onRefresh={onRefreshCategories}
                    />
                  )}
                  {addCategoryFormIsOpen && (
                    <AddCategory
                      selectedBranch={selectedBranch}
                      setAddCategoryFormIsOpen={setAddCategoryFormIsOpen}
                      primaryLng={createdLanguages
                        .filter(({ isPrimary }) => isPrimary)[0]
                        .name?.toLowerCase()}
                      onRefreshCategories={onRefreshCategories}
                      languageId={
                        createdLanguages.filter(({ isPrimary }) => isPrimary)[0]
                          .id
                      }
                    />
                  )}
                  {editableLanguage && (
                    <EditLanguage
                      setEditableLanguage={setEditableLanguage}
                      editableLanguage={editableLanguage}
                      setCreatedLanguages={setCreatedLanguages}
                      setSelectedLng={setSelectedLng}
                    />
                  )}
                  {deleteMenuWarning && (
                    <Warning
                      description={`${t('components.warning.mainTxt')} ${t('components.warning.deleteMenu')}`}
                      onCancel={() => setDeleteMenuWarning(false)}
                      onSubmit={onMenuDelete}
                    />
                  )}
                  {deletableLanguage && (
                    <Warning
                      description={`${t('components.warning.mainTxt')} ${t('components.warning.block')} ${deletableLanguage.name}`}
                      onCancel={() => setDeletableLanguage(null)}
                      onSubmit={onLanguageDelete}
                    />
                  )}
                </>
              )}
              {importing && (
                <>
                  <div className='menu_in_content_importing'>
                    <div className='menu_in_content_importing_title'>
                      <h2>{t('dashboard.menu.importingTitle')}</h2>
                      <h3>{t('dashboard.menu.importingSubtitle')}</h3>
                      <p>{t('dashboard.menu.importingDescription')}</p>
                    </div>
                    <div className='load-wrapp'>
                      <div className='load-7'>
                        <div className='square-holder'>
                          <div className='square'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Overlay isTransparent={true} />
                </>
              )}
              {deleting && (
                <div className='menu_in_content_importing'>
                  <div className='menu_in_content_importing_title'>
                    <h2>{t('dashboard.menu.importingTitle')}</h2>
                    <h3>{t('dashboard.menu.deletingSubtitle')}</h3>
                    <p>{t('dashboard.menu.importingDescription')}</p>
                  </div>
                  <div className='load-wrapp'>
                    <div className='load-7'>
                      <div className='square-holder'>
                        <div className='square'></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loading && (
                <div className='loading_fullscreen'>
                  <Loading />
                </div>
              )}
            </div>
          )}
          {(!integrations || loading) && (
            <div className='loading_fullscreen'>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Menu);
