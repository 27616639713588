import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import moment from 'moment';
import { useCallback } from 'react';

export default function ResponsiveDateTimePickers() {
  const [date, setDate] = React.useState('');

  const onBook = useCallback((date) => {
    setDate(moment(date?.$d).format('DD/MM/YYYY HH:mm'));
    alert('onBook()');
  }, []);

  console.log('booking date and time', date);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} localeText='Text'>
      <DemoContainer
        components={['MobileDateTimePicker', 'StaticDateTimePicker']}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 3,
        }}
      >
        <DemoItem
          sx={{
            marginBottom: 3,
            maxWidth: 400,
            width: '100%',
          }}
        >
          <StaticDateTimePicker
            defaultValue={dayjs(new Date())}
            onAccept={(date) => {
              onBook(date);
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
