import React, { memo, useCallback, useState } from 'react';
import api from '../../../../utils/api';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import Loading from '../../../../components/loading';

function EditBranch({ setEditBranch, selectedBranch, setBranches, branches }) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [branchName, setBranchName] = useState(selectedBranch.name);
  const [branchAddress, setBranchAddress] = useState(selectedBranch.address);
  const [wifiPassword, setWifiPassword] = useState(selectedBranch.branchInfo.wifiPassword);
  const [wifiUsername, setWifiUsername] = useState(selectedBranch.branchInfo.wifiUsername);
  const [branchPhoneNumber, setBranchPhoneNumber] = useState(selectedBranch.branchInfo.phoneNumber);
  const [serviceFee, setServiceFee] = useState(selectedBranch.branchInfo.serviceFee);

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
    setError(null);
  }, []);

  const onEditBranch = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      setBtnLoading(true);

      const data = {
          name: branchName,
          address: branchAddress,
          phoneNumber: branchPhoneNumber,
          wifiUsername: wifiUsername,
          wifiPassword: wifiPassword,
          serviceFee: setServiceFee,
      };

      api
        .post(`/branches/edit/${selectedBranch.id}`, data)
        .then(() => {
          setEditBranch(false);
          selectedBranch(null);
          setError(null);
          setBtnLoading(false);
          const audio = new Audio(sound);
          const info = `Password was updated`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          return setBranches(branches.map(branch => {
              if (branch.id !== selectedBranch.id) {
                  return branch
              } else {
                  return {
                      ...selectedBranch,
                      address: branchAddress,
                      name: branchName,
                      branchInfo: {
                          ...selectedBranch.branchInfo,
                          wifiPassword,
                          wifiUsername,
                          serviceFee,
                          phoneNumber: branchPhoneNumber,
                      }
                  }
              }
          }));
        })
        .catch((err) => {
          setError(t(`errors.${errToString(err)}`));
          setBtnLoading(false);
        });
    },
    [t, branchName, branchAddress, branchPhoneNumber, wifiPassword, wifiUsername, serviceFee, setBranches, branches, setEditBranch, selectedBranch]
  );

  return (
      <form className='form' onSubmit={onEditBranch}>
          <div className='form_columns'>
              <div className='form_column'>
                  <label htmlFor=''>{t('components.tables.name')}</label>
                  <input
                      type='text'
                      value={branchName}
                      maxLength={50}
                      placeholder={t('components.tables.name')}
                      onChange={(e) => onChangeField(e, setBranchName)}
                  />
              </div>
              <div className='form_column'>
                  <label htmlFor=''>
                      {t('dashboard.restaurant.address')}
                  </label>
                  <input
                      type='text'
                      value={branchAddress}
                      maxLength={50}
                      placeholder={t('dashboard.restaurant.address')}
                      onChange={(e) => onChangeField(e, setBranchAddress)}
                  />
              </div>
              <div className='form_column'>
                  <label htmlFor=''>
                      {t('dashboard.restaurant.phone')}
                  </label>
                  <input
                      type='text'
                      value={branchPhoneNumber}
                      placeholder={t('dashboard.restaurant.phone')}
                      onChange={(e) =>
                          onChangeField(e, setBranchPhoneNumber)
                      }
                  />
              </div>
          </div>
          <div className='form_columns'>
              <div className='form_column'>
                  <label htmlFor=''>
                      {t('dashboard.restaurant.wifi_username')}
                  </label>
                  <input
                      type='text'
                      value={wifiUsername}
                      maxLength={50}
                      placeholder={t('dashboard.restaurant.wifi_username')}
                      onChange={(e) => onChangeField(e, setWifiUsername)}
                  />
              </div>
              <div className='form_column'>
                  <label htmlFor=''>
                      {t('dashboard.restaurant.wifi_password')}
                  </label>
                  <input
                      type='text'
                      value={wifiPassword}
                      maxLength={50}
                      placeholder={t('dashboard.restaurant.wifi_password')}
                      onChange={(e) => onChangeField(e, setWifiPassword)}
                  />
              </div>
          </div>
          <div className='form_columns'>
              <div className='form_column'>
                  <label htmlFor=''>
                      {t('dashboard.restaurant.fee')}
                  </label>
                  <input
                      type='text'
                      value={serviceFee}
                      maxLength={50}
                      placeholder={t('dashboard.restaurant.fee')}
                      onChange={(e) => onChangeField(e, setServiceFee)}
                  />
              </div>
              <div className='form_column'/>
          </div>
          <div className='form_error form_error--left'>
              {error && (
                  <div className='form_error_name'>
                      <PiWarningOctagonDuotone/>
                      <p>{error}</p>
                  </div>
              )}
          </div>
          <div className='form_actions'>
              <button className='form_actions_btn' type={'submit'}>
                  {!btnLoading && t('general.save')}
                  {btnLoading && <Loading/>}
              </button>
          </div>
      </form>
  );
}

export default memo(EditBranch);
