import './index.scss';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Wrapper from '../../../components/layout/wrapper';
import Table from '../../../components/table';
import NoData from '../../../components/no-data';
import { AiTwotoneEdit } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import EditStaff from './edit-staff';
import Warning from '../../../components/warning';
import api from '../../../utils/api';
import Loading from '../../../components/loading';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import DropDown from '../../../components/drop-down';

const ROLES = [
  {
    value: 'staff',
    label: 'Staff',
  },
  {
    value: 'manager',
    label: 'Manager',
  },
];

function Staff() {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [branches, setBranches] = useState('');
  const [selectedBranchField, setSelectedBranchField] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [error, setError] = useState(null);
  const [addNewStaff, setAddNewStaff] = useState(false);
  const [staffIsEditing, setStaffIsEditing] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [staff, setStaff] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [role, setRole] = useState(ROLES[0]);

  const StaffTitles = useMemo(
    () => [
      {
        name: t('general.firstName'),
        size: 150,
      },
      {
        name: t('general.lastName'),
        size: 150,
      },
      {
        name: t('components.tables.email'),
        size: 200,
      },
      {
        name: t('general.username'),
        size: 200,
      },
      {
        name: t('general.role'),
        size: 200,
      },
      {},
    ],
    [t]
  );

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
    setError(null);
  }, []);
  const onCreateStaff = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      const data = {
        branchId: selectedBranchField.id,
        firstName,
        lastName,
        userName: username,
        email,
        role: role.value,
      };
      setBtnLoading(true);

      api
        .post('/companies/add/staff', data)
        .then((res) => {
          setBtnLoading(false);
          setAddNewStaff(false);
          setError(null);
          setFirstName('');
          setEmail('');
          setLastName('');
          setUsername('');
          setStaff([...staff, res.data]);
          const audio = new Audio(sound);
          const info = t('notifications.userCreated');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
        })
        .catch((err) => {
          setError(t(`errors.${errToString(err)}`));
          setBtnLoading(false);
        });
    },
    [t, email, firstName, lastName, selectedBranchField, username, staff, role]
  );
  const onStaffDelete = useCallback(() => {
    api
      .delete(`/companies/staff/${selectedStaff.id}`)
      .then((res) => {
        setWarning(false);

        const audio = new Audio(sound);
        const info = `${selectedStaff.firstName} ${selectedStaff.lastName} ${t('notifications.userDeleted')}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();

        return setStaff(
          staff
            .map((staff) => {
              if (selectedStaff.id === staff.id) {
                return {
                  ...staff,
                  deletedAt: new Date(),
                };
              }
              return staff;
            })
            .filter((staff) => staff.id !== selectedStaff.id)
        );
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [t, selectedStaff, staff]);

  useEffect(() => {
    setLoading(true);

    if (!selectedBranch) {
      return;
    }

    api
      .get(`/companies/staff/all/${selectedBranch.id}`)
      .then((res) => {
        setStaff(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
        setLoading(false);
      });
  }, [t, selectedBranch]);
  useEffect(() => {
    api
      .get(`/branches`)
      .then((res) => {
        setBranches(
          res.data.map((obj) => ({
            ...obj,
            value: obj.id,
            label: obj.name,
          }))
        );
        setLoading(false);
        setSelectedBranchField(
          res.data.map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj.id,
          }))[0]
        );
        setSelectedBranch(
          res.data.map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj.id,
          }))[0]
        );
      })
      .catch((err) => console.log(t(`errors.${errToString(err)}`)));
  }, [t]);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            {!staffIsEditing && !addNewStaff && (
              <>
                <h2>{t('routes.staff')}</h2>
                <div className='page_in_header_filter'>
                  {!!branches.length && (
                    <DropDown
                      placeholder={t('routes.branches')}
                      options={branches
                        .filter(({ deletedAt }) => !deletedAt)
                        .map((branch, index) => ({
                          option: branch,
                          el: <li key={index}>{branch.label}</li>,
                        }))}
                      value={selectedBranch}
                      onChange={(e) => setSelectedBranch(e)}
                      position='bottom'
                      loading={false}
                      showClose={false}
                    />
                  )}
                </div>
                <button
                  className='page_in_header_btn page_in_header_btn--add'
                  onClick={() => setAddNewStaff(true)}
                >
                  {t('general.addStaff')}
                </button>
              </>
            )}
            {!staffIsEditing && addNewStaff && (
              <>
                <h2>{t('general.addStaff')}</h2>
                <button
                  className='page_in_header_btn'
                  onClick={() => {
                    setAddNewStaff(false);
                    setFirstName('');
                    setEmail('');
                    setLastName('');
                    setUsername('');
                    setError(null);
                  }}
                >
                  {t('general.back')}
                </button>
              </>
            )}
            {!addNewStaff && staffIsEditing && (
              <>
                <h2>
                  {t('general.edit')} {selectedStaff.name}
                </h2>
                <button
                  className='page_in_header_btn'
                  onClick={() => setStaffIsEditing(false)}
                >
                  {t('general.edit')}
                </button>
              </>
            )}
          </div>
          {!loading && !!branches.length && (
            <div className='page_in_content'>
              {!staffIsEditing && addNewStaff && (
                <form className='form' onSubmit={onCreateStaff}>
                  <div className='form_columns'>
                    <div className='form_column'>
                      <label htmlFor=''>{t('general.firstName')}</label>
                      <input
                        type='text'
                        value={firstName}
                        placeholder={t('general.firstName')}
                        onChange={(e) => onChangeField(e, setFirstName)}
                      />
                    </div>
                    <div className='form_column'>
                      <label htmlFor=''>{t('general.lastName')}</label>
                      <input
                        type='text'
                        value={lastName}
                        placeholder={t('general.lastName')}
                        onChange={(e) => onChangeField(e, setLastName)}
                      />
                    </div>
                    <div className='form_column'>
                      <label htmlFor=''>{t('general.email')}</label>
                      <input
                        type='text'
                        value={email}
                        placeholder={t('general.email')}
                        onChange={(e) => onChangeField(e, setEmail)}
                      />
                    </div>
                  </div>
                  <div className='form_columns'>
                    <div className='form_column'>
                      <label htmlFor=''>{t('general.username')}</label>
                      <input
                        type='text'
                        value={username}
                        placeholder={t('general.username')}
                        onChange={(e) => onChangeField(e, setUsername)}
                      />
                    </div>
                    <div className='form_column'>
                      <label htmlFor=''>{t('routes.branches')}</label>
                      <DropDown
                        placeholder={t('routes.branches')}
                        options={branches.map((branch, index) => ({
                          option: branch,
                          el: <li key={index}>{branch.label}</li>,
                        }))}
                        value={selectedBranchField}
                        onChange={(e) => setSelectedBranchField(e)}
                        position='bottom'
                        loading={false}
                      />
                    </div>
                    <div className='form_column'>
                      <label htmlFor=''>{t('general.role')}</label>
                      <DropDown
                        placeholder={t('general.role')}
                        options={ROLES.map((role, index) => ({
                          option: role,
                          el: <li key={index}>{role.label}</li>,
                        }))}
                        value={role}
                        onChange={(e) => setRole(e)}
                        position='bottom'
                        loading={false}
                      />
                    </div>
                  </div>
                  <div className='form_error form_error--left'>
                    {error && (
                      <div className='form_error_name'>
                        <PiWarningOctagonDuotone />
                        <p>{error}</p>
                      </div>
                    )}
                  </div>
                  <div className='form_actions'>
                    <button className='form_actions_btn' type={'submit'}>
                      {!btnLoading && t('general.save')}
                      {btnLoading && <Loading />}
                    </button>
                  </div>
                </form>
              )}
              {!addNewStaff && staffIsEditing && (
                <EditStaff
                  setStaffIsEditing={setStaffIsEditing}
                  selectedStaff={selectedStaff}
                  setStaff={setStaff}
                  staff={staff}
                />
              )}
              {!staffIsEditing && !addNewStaff && !!staff.length && (
                <Table
                  route={false}
                  titles={StaffTitles}
                  rows={
                    staff.map((staff) => ({
                      id: staff.id,
                      firstName: <p>{staff.firstName}</p>,
                      lastName: <p>{staff.lastName}</p>,
                      email: <p>{staff.email}</p>,
                      username: <p>{staff.userName}</p>,
                      role: <p>{staff.role}</p>,
                      actions: (
                        <div className='page_in_content_actions'>
                          <button
                            className='page_in_content_actions_btn page_in_content_actions_btn--edit'
                            onClick={() => {
                              setSelectedStaff(staff);
                              setWarning(false);
                              setAddNewStaff(false);
                              setStaffIsEditing(true);
                            }}
                          >
                            <AiTwotoneEdit />
                          </button>
                          <button
                            className='page_in_content_actions_btn page_in_content_actions_btn--delete'
                            onClick={() => {
                              setSelectedStaff(staff);
                              setAddNewStaff(false);
                              setStaffIsEditing(false);
                              setWarning(true);
                            }}
                          >
                            <IoMdClose />
                          </button>
                        </div>
                      ),
                    })) || []
                  }
                  hiddenFields={['id']}
                  loading={false}
                />
              )}
              {!loading && !staffIsEditing && !addNewStaff && !staff.length && (
                <NoData title={t('noData.staff')} />
              )}
              {warning && (
                <Warning
                  description={`${t('components.warning.mainTxt')} ${t('components.warning.block')} ${selectedStaff.firstName} ${selectedStaff.lastName}`}
                  onCancel={() => setWarning(false)}
                  onSubmit={onStaffDelete}
                />
              )}
            </div>
          )}
          {(loading || !branches.length) && (
            <div className='loading_fullscreen'>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Staff);
