import { memo, useContext, useEffect, useState } from 'react';
import Router from './Router';
import { useLocation, useNavigate } from 'react-router-dom';
import Navigation from './components/navigation';
import AuthContext from './context/auth/auth-context';
import api from './utils/api';
import PageHeader from './components/layout/page-header';
import isRouteAllowed from './utils/is-route-allowed';
import { NOT_ALLOWED_NAVIGATION_ROUTES } from './constants/not-allowed-navigation-routes';
import PushNotifications from './components/push-notifications';
import io from 'socket.io-client';
import SocketContext from './context/socket/socket-context';
import { useTranslation } from 'react-i18next';
import Loading from './components/loading';

function App() {
  const { isAuth, user, signIn } = useContext(AuthContext);
  const { setSocket } = useContext(SocketContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (
      isAuth &&
      (pathname === '/auth' ||
        pathname === '/auth/sign-up' ||
        pathname === '/auth/sign-up/am' ||
        pathname === '/auth/sign-up/ru')
    ) {
      navigate('/', { replace: true });
    }

    if ((!isAuth || isAuth === false) && pathname === '/') {
      navigate('/auth', { replace: true });
    }

    if (isAuth !== null) {
      return;
    }

    if (pathname.includes('/shop')) {
      return signIn(null);
    }

    if (pathname.includes('/booking')) {
      return signIn(null);
    }

    api
      .get('/companies/token')
      .then(({ data }) => signIn(data))
      .catch((err) => {
        signIn(null);

        if (err.response.status === 401) {
          return navigate('/auth', { replace: true });
        }
        if (pathname === '/auth') {
          return navigate('/auth', { replace: true });
        }
        if (pathname === '/auth/sign-up') {
          return navigate('/auth/sign-up', { replace: true });
        }
        if (pathname === '/auth/sign-up/am') {
          return navigate('/auth/sign-up/am', { replace: true });
        }
        if (pathname === '/auth/sign-up/ru') {
          return navigate('/auth/sign-up/ru', { replace: true });
        }
      });
  }, [pathname, signIn, navigate, isAuth]);
  useEffect(() => {
    if (!user) {
      return;
    }

    const token = localStorage.getItem('token');
    const socket = io(process.env.REACT_APP_BASE_URL, {
      transports: ['websocket'],
      auth: {
        userToken: token,
      },
    });

    socket.on('connect', () => {
      setSocket(socket);
      console.log('connected');
    });
    socket.on('disconnect', (reason) => {
      setSocket(null);
      console.log('disconnect', reason);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      setSocket(null);
    };
  }, [user, setSocket]);
  useEffect(() => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'));
    }
  }, [i18n]);

  if (isAuth === null) {
    return (
      <div className='loading_fullscreen'>
        <Loading />
      </div>
    );
  }

  return (
    <>
      {pathname !== '/not-found' &&
        !pathname.includes('/auth') &&
        !pathname.includes('/shop') &&
        !pathname.includes('/booking') && (
          <>
            <Navigation showMenu={showMenu} setShowMenu={setShowMenu} />
            <PageHeader showMenu={showMenu} setShowMenu={setShowMenu} />
          </>
        )}
      <Router />
      {isAuth && isRouteAllowed(NOT_ALLOWED_NAVIGATION_ROUTES, pathname) && (
        <PushNotifications />
      )}
    </>
  );
}

export default memo(App);
