import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import './index.scss';
import AuthContext from '../../../context/auth/auth-context';
import Wrapper from '../../../components/layout/wrapper';
import DropDown from '../../../components/drop-down';
import api from '../../../utils/api';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import Loading from '../../../components/loading';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../utils';

function Settings() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [integrations, setIntegrations] = useState(null);
  const [restaurantName, setRestaurantName] = useState(user.restaurant.name);
  const [currencies, setCurrencies] = useState(null);
  const [currencyOption, setCurrencyOption] = useState(null);
  const [mainColor, setMainColor] = useState(
    user.restaurant.mainColor.split('#')[1]
  );
  const [logo, setLogo] = useState(user.restaurant.img);
  const [imageSrc, setImageSrc] = useState(user.restaurant.img);
  const [pending, setPending] = useState(false);

  const onSave = useCallback(
    (e) => {
      e.preventDefault();

      if (pending) {
        return;
      }

      setPending(true);
      const formData = new FormData();
      if (imageSrc) {
        formData.append('image', imageSrc);
      }

      formData.append('name', restaurantName || user.restaurant.name);
      formData.append('mainColor', `#${mainColor}`);
      formData.append(
        'restaurantInfo',
        JSON.stringify({
          currency:
            currencyOption.value?.toLowerCase() ||
            user.restaurant.restaurantInfo.currency,
          highRatingUrl: user.restaurant.restaurantInfo.highRatingUrl,
          lowRatingUrl: user.restaurant.restaurantInfo.lowRatingUrl,
        })
      );
      api
        .put('/restaurant', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(() => {
          const audio = new Audio(sound);
          const info = t('notifications.prefUpdate');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          setPending(false);
          window.location.reload();
        })
        .catch((err) => {
          setPending(false);
          const audio = new Audio(sound);
          const info = `Something went wrong`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          console.log(errToString(err));
        });
    },
    [user, pending, t, restaurantName, currencyOption, mainColor, imageSrc]
  );

  useEffect(() => {
    api
      .get('https://openexchangerates.org/api/currencies.json')
      .then(({ data }) => {
        const currencies = [];

        for (const key in data) {
          currencies.push({
            value: key,
            label: `${key} - ${data[key]}`,
          });
        }
        setCurrencies(currencies);
        return setCurrencyOption(
          currencies.find(
            ({ value }) =>
              value?.toLowerCase() ===
              user.restaurant.restaurantInfo.currency?.toLowerCase()
          )
        );
      })
      .catch((err) => {
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [user]);
  useEffect(() => {
    api
      .get('/restaurant/integrations')
      .then((res) => {
        setIntegrations(res.data);
      })
      .catch((err) => {
        errToString(err);
      });
  }, []);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            <h2>{t('routes.settings')}</h2>
          </div>
          {integrations && (
            <div className='page_in_content'>
              <form className='form' onSubmit={onSave}>
                <div className='form_columns'>
                  <div className='form_column'>
                    <label htmlFor=''>{t('dashboard.restaurant.name')}</label>
                    <input
                      type='text'
                      value={restaurantName}
                      placeholder={t('dashboard.restaurant.name')}
                      onChange={(e) => setRestaurantName(e.target.value)}
                    />
                  </div>
                  <div className='form_column'>
                    <label htmlFor=''>
                      {t('dashboard.restaurant.currency')}
                    </label>
                    <DropDown
                      hasCurrencyIcon={true}
                      placeholder={t('dashboard.restaurant.currency')}
                      position='top'
                      showClose={false}
                      options={currencies || []}
                      value={currencyOption || {}}
                      onChange={(option) => {
                        if (option.value === currencyOption.value) {
                          return;
                        }
                        setCurrencyOption(option);
                      }}
                    />
                  </div>
                  <div className='form_column' />
                </div>
                <div className='form_columns'>
                  <div className='form_column'>
                    <label htmlFor=''>{t('general.chooseMainColor')}</label>
                    <div className='form_color_picker'>
                      <span className='form_color_picker_icon'>#</span>
                      <input
                        type='text'
                        maxLength={6}
                        style={{
                          textIndent: '20px',
                          textTransform: 'uppercase',
                        }}
                        value={mainColor}
                        placeholder='FFFFFF'
                        onChange={(e) => setMainColor(e.target.value)}
                      />
                      {mainColor?.length > 3 && (
                        <span
                          className='form_color_picker_color'
                          style={{ backgroundColor: `#${mainColor}` }}
                        />
                      )}
                    </div>
                  </div>
                  <div className='form_column' />
                  <div className='form_column' />
                </div>
                <div className='form_columns'>
                  <div className='form_column'>
                    <label htmlFor='file'>
                      {t('general.chooseLogo')} (150x25px)
                    </label>
                    <div className='form_column_img'>
                      <img alt='logo' src={logo} />
                    </div>
                    <label className='form_file_btn' htmlFor='file'>
                      {t('general.edit')}
                    </label>
                    <input
                      id='file'
                      type='file'
                      name='partnerSrc'
                      onChange={(event) => {
                        setImageSrc(event.target.files[0]);
                        setLogo(URL.createObjectURL(event.target.files[0]));
                      }}
                    />
                  </div>
                  <div className='form_column' />
                  <div className='form_column' />
                </div>
                <div className='form_actions'>
                  {!pending && (
                    <button className='form_actions_btn' type={'submit'}>
                      {t('general.save')}
                    </button>
                  )}
                  {pending && (
                    <button
                      onClick={() => {}}
                      className='form_actions_btn form_actions_btn--loading'
                    >
                      <Loading />
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
          {!integrations && <Loading />}
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Settings);
