import './index.scss';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { IoMdClose } from 'react-icons/io';
import { toPriceWithCurrency } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../../../context/auth/auth-context';

function TerminalCategoryProductDetails({
  product,
  setShowProductDetailsPage,
  onAddItem,
}) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [options, setOptions] = useState(
    product.options.map((option) => {
      return {
        ...option,
        isActive: false,
      };
    })
  );
  const [isMount, setIsMount] = useState(false);
  const totalAmount = useMemo(() => {
    if (!product.options.length) {
      return product.price;
    }
    return (
      options
        .filter(({ isActive }) => isActive)
        .reduce((acc, val) => acc + val.price, 0) + product.price
    );
  }, [product, options]);

  const onChangeExtraStatus = useCallback(
    (extra) => {
      return setOptions(
        options.map((option) => {
          if (option.id === extra.id) {
            return {
              ...option,
              isActive: true,
            };
          }
          return {
            ...option,
            isActive: false,
          };
        })
      );
      // if (product.optionSelectionType === 'single') {
      //   return setOptions(
      //     options.map((option) => {
      //       if (option.id === extra.id) {
      //         return {
      //           ...option,
      //           isActive: true,
      //         };
      //       }
      //       return {
      //         ...option,
      //         isActive: false,
      //       };
      //     })
      //   );
      // }
      // if (product.optionSelectionType === 'multiple') {
      //   return setOptions(
      //     options.map((option) => {
      //       if (option.id === extra.id) {
      //         return {
      //           ...option,
      //           isActive: !option.isActive,
      //         };
      //       }
      //       return option;
      //     })
      //   );
      // }
    },
    [options]
  );

  useEffect(() => {
    setIsMount(true);
  }, []);

  return createPortal(
    <>
      <div
        className={cn('terminal_category_product_details', {
          'terminal_category_product_details--show': isMount,
        })}
      >
        <div className='add_product_extra_in_header'>
          <div className='add_product_extra_in_header_title'>
            <h2>{product.name}</h2>
          </div>
          <i onClick={() => setShowProductDetailsPage(false)}>
            <IoMdClose />
          </i>
        </div>
        <div className='terminal_category_product_details_in'>
          <div className='terminal_category_product_details_wrapper'>
            <h3 className='terminal_category_product_details_wrapper_name'>
              {product.name}
            </h3>
            {product.description !== 'n/a' && (
              <p className='terminal_category_product_details_wrapper_description'>
                {product.description}
              </p>
            )}
            {product.price > 0 && (
              <p className='terminal_category_product_details_wrapper_price'>
                {toPriceWithCurrency(
                  Number(product.price),
                  user.restaurant.restaurantInfo.currency
                )}
              </p>
            )}
          </div>
          {!!product.options.length && (
            <div className='terminal_category_product_details_extras'>
              {options.map((extra) => (
                <div
                  className='terminal_category_product_details_extras_extra'
                  key={extra.id}
                  onClick={() => onChangeExtraStatus(extra)}
                >
                  <input
                    id={extra.id}
                    type='checkbox'
                    checked={extra.isActive}
                    onChange={() => onChangeExtraStatus(extra)}
                  />
                  <p className='terminal_category_product_details_extras_extra_name'>
                    {extra.name}
                  </p>
                  <p className='terminal_category_product_details_extras_extra_price'>
                    {toPriceWithCurrency(
                      Number(extra.price),
                      user.restaurant.restaurantInfo.currency
                    )}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='terminal_category_product_details_footer'>
          <button
            onClick={() => {
              setShowProductDetailsPage(false);
              onAddItem({
                ...product,
                options: options.filter(({ isActive }) => isActive),
              });
            }}
          >
            {t('dashboard.terminal.addProduct')} -{' '}
            {toPriceWithCurrency(
              Number(totalAmount),
              user.restaurant.restaurantInfo.currency
            )}
          </button>
        </div>
      </div>
      <div
        className='terminal_category_product_details_overlay'
        onClick={() => setShowProductDetailsPage(false)}
      />
    </>,
    document.getElementById('modal')
  );
}

TerminalCategoryProductDetails.propTypes = {
  product: PropTypes.object.isRequired,
  setShowProductDetailsPage: PropTypes.func.isRequired,
};

export default memo(TerminalCategoryProductDetails);
