import { useReducer, useCallback, memo } from 'react';
import ShopContext from './shop-context';

const restaurantId = window.location.pathname.split('/')[2];
const tableId = window.location.pathname.split('/')[4];

function getProducts() {
  const productsFromLocalStorage = localStorage.getItem(
    `basket_${restaurantId}_${tableId}`
  );

  if (productsFromLocalStorage) {
    return [
      ...JSON.parse(localStorage.getItem(`basket_${restaurantId}_${tableId}`))
        .products,
    ];
  }

  return [];
}

const defaultShopState = {
  products: getProducts(),
  onAddItem: () => {},
  onEditItem: () => {},
  onRemoveItem: () => {},
  onRemoveItemAll: () => {},
  onRemoveItemSingleItem: () => {},
  onClear: () => {},
};

const shopReducer = (state, action) => {
  console.log(state, 'test');
  if (action.type === 'ADD_ITEM') {
    const productsObj = {};
    for (const product of state.products) {
      const key = `${product.id}:${product.options.map(({ id }) => id).join(',')}:${product.note}`;
      productsObj[key] = {
        ...product,
      };
    }

    const products = [];
    const key = `${action.payload.product.id}:${action.payload.product.options.map(({ id }) => id).join(',')}:${action.payload.product.note ? action.payload.product.note : ''}`;

    if (productsObj[key]) {
      productsObj[key].qty += 1;
    }
    if (!productsObj[key]) {
      productsObj[key] = {
        ...action.payload.product,
        qty: 1,
      };
    }

    for (const key in productsObj) {
      products.push(productsObj[key]);
    }

    const data = {
      ...state,
      products,
    };

    localStorage.setItem(
      `basket_${restaurantId}_${tableId}`,
      JSON.stringify(data)
    );
    return data;
  }
  if (action.type === 'EDIT_ITEM') {
    const editableItemKey = localStorage.getItem('editableItemKey');

    const data = {
      ...state,
      products: [
        ...state.products.filter((product) => {
          const productKey = `${product.id}:${product.options
            .filter(({ isActive }) => isActive)
            .map(({ name }) => name)
            .join(',')}:${product.note ? product.note : ''}`;
          return productKey !== editableItemKey;
        }),
        {
          ...action.payload.product,
          options: action.payload.product.options.filter(
            ({ isActive }) => isActive
          ),
        },
      ],
    };

    localStorage.setItem(
      `basket_${restaurantId}_${tableId}`,
      JSON.stringify(data)
    );
    return data;
  }
  if (action.type === 'REMOVE_ITEM') {
    const productsObj = {};
    for (const product of state.products) {
      const key = `${product.id}:${product.options.map(({ id }) => id).join(',')}:${product.note}`;
      productsObj[key] = {
        ...product,
      };
    }

    const products = [];
    const key = `${action.payload.product.id}:${action.payload.product.options.map(({ id }) => id).join(',')}:${action.payload.product.note ? action.payload.product.note : ''}`;

    if (productsObj[key]) {
      productsObj[key].qty -= 1;
    }

    for (const key in productsObj) {
      products.push(productsObj[key]);
    }

    const data = {
      ...state,
      products: products.filter(({ qty }) => qty > 0),
    };

    localStorage.setItem(
      `basket_${restaurantId}_${tableId}`,
      JSON.stringify(data)
    );
    return data;
  }
  if (action.type === 'REMOVE_ITEM_All') {
    const productsObj = {};
    for (const product of state.products) {
      const key = `${product.id}:${product.options.map(({ id }) => id).join(',')}:${product.note}`;
      productsObj[key] = {
        ...product,
      };
    }

    const products = [];
    const key = `${action.payload.product.id}:${action.payload.product.options.map(({ id }) => id).join(',')}:${action.payload.product.note ? action.payload.product.note : ''}`;

    if (productsObj[key]) {
      productsObj[key].qty = 0;
    }

    for (const key in productsObj) {
      products.push(productsObj[key]);
    }

    const data = {
      ...state,
      products: products.filter(({ qty }) => qty > 0),
    };

    localStorage.setItem(
      `basket_${restaurantId}_${tableId}`,
      JSON.stringify(data)
    );
    return data;
  }
  if (action.type === 'REMOVE_SINGLE_ITEM') {
    const data = {
      ...state,
      products: state.products.filter(
        ({ id }) => id !== action.payload.product.id
      ),
    };

    localStorage.setItem(
      `basket_${restaurantId}_${tableId}`,
      JSON.stringify(data)
    );
    return data;
  }
  if (action.type === 'CLEAR') {
    const data = {
      ...state,
      products: [],
    };

    localStorage.setItem(
      `basket_${restaurantId}_${tableId}`,
      JSON.stringify(data)
    );
    return data;
  }

  return defaultShopState;
};

const ShopProvider = ({ children }) => {
  const [state, dispatch] = useReducer(shopReducer, defaultShopState);

  const onAddItem = useCallback(
    (product) => {
      dispatch({
        type: 'ADD_ITEM',
        payload: { product },
      });
    },
    [dispatch]
  );
  const onEditItem = useCallback(
    (product) => {
      dispatch({
        type: 'EDIT_ITEM',
        payload: { product },
      });
    },
    [dispatch]
  );
  const onRemoveItem = useCallback(
    (product) => {
      dispatch({
        type: 'REMOVE_ITEM',
        payload: { product },
      });
    },
    [dispatch]
  );
  const onRemoveItemAll = useCallback(
    (product) => {
      dispatch({
        type: 'REMOVE_ITEM_All',
        payload: { product },
      });
    },
    [dispatch]
  );
  const onRemoveItemSingleItem = useCallback(
    (product) => {
      dispatch({
        type: 'REMOVE_SINGLE_ITEM',
        payload: { product },
      });
    },
    [dispatch]
  );
  const onClear = useCallback(
    (product) => {
      dispatch({
        type: 'CLEAR',
        payload: { product },
      });
    },
    [dispatch]
  );

  const shopContext = {
    products: state.products,
    onAddItem: onAddItem,
    onEditItem: onEditItem,
    onRemoveItem: onRemoveItem,
    onRemoveItemAll: onRemoveItemAll,
    onRemoveItemSingleItem: onRemoveItemSingleItem,
    onClear: onClear,
  };

  return (
    <ShopContext.Provider value={shopContext}>{children}</ShopContext.Provider>
  );
};

export default memo(ShopProvider);
