import React, { useCallback, useState } from 'react';
import './index.scss';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import Loading from '../../components/loading';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/logo/QRrobo version 1 - white.png';
import BookingCalendar from '../../components/calendar/booking-calendar';
import { getCurrentDateWithoutHours } from '../../utils/date';

const Booking = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(null);
  const [loading] = useState(false);
  const [page, setPage] = useState(1);
  const [dateFields, setDateFields] = useState({
    startDate: getCurrentDateWithoutHours(),
    endDate: getCurrentDateWithoutHours(23, 59, 59),
  });

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
    setError(null);
  }, []);

  const onBook = useCallback(() => {}, []);

  return (
    <div className='booking'>
      <div className='booking_header'>
        <img src={Logo} alt='qrrobo' />
      </div>
      <div className='booking_form booking_form--active'>
        <p className='booking_form_title'>Welcome</p>
        <p className='booking_form_description'>
          {page === 1 && 'Fill in your details for reservation'}
          {page === 2 &&
            'Choose date for reservation and our manager will contact you to approve it'}
        </p>
        <div className='booking_form_in'>
          {page === 1 && (
            <form className='booking_form_in_form' onSubmit={onBook}>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>{t('general.firstName')}</label>
                  <input
                    type='text'
                    value={firstName}
                    placeholder={t('general.firstName')}
                    onChange={(e) => onChangeField(e, setFirstName)}
                  />
                </div>
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>{t('general.lastName')}</label>
                  <input
                    type='text'
                    value={lastName}
                    placeholder={t('general.lastName')}
                    onChange={(e) => onChangeField(e, setLastName)}
                  />
                </div>
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>{t('general.email')}</label>
                  <input
                    type='text'
                    value={email}
                    placeholder={t('general.email')}
                    onChange={(e) => onChangeField(e, setEmail)}
                  />
                </div>
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>{t('general.phoneNumber')}</label>
                  <input
                    type='text'
                    value={phoneNumber}
                    placeholder={t('general.phoneNumber')}
                    onChange={(e) => onChangeField(e, setPhoneNumber)}
                  />
                </div>
              </div>
              <div className='form_error form_error--left'>
                {error && (
                  <div className='form_error_name'>
                    <PiWarningOctagonDuotone />
                    <p>{error}</p>
                  </div>
                )}
              </div>
              <div className='form_actions'>
                {page === 1 && (
                  <button
                    className='booking_form_btn'
                    onClick={() => {
                      setPage(2);
                    }}
                  >
                    {t('general.next')}
                  </button>
                )}
                {page === 2 && (
                  <button className='booking_form_btn' type={'submit'}>
                    {!loading && t('general.save')}
                    {loading && <Loading />}
                  </button>
                )}
              </div>
            </form>
          )}
          {page === 2 && (
            <BookingCalendar
              placeholder='DD/MM/YYYY HH:mm'
              enableTimePicker={true}
              position='bottom'
              theme='orders'
              value={[dateFields.startDate, dateFields.endDate]}
              onChange={(e) => setDateFields(e)}
            />
          )}
        </div>
        <div className='booking_form_footer'>
          In case you have questions, please call 044430049
        </div>
      </div>
    </div>
  );
};

export default Booking;
